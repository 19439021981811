import React from 'react'
import App from 'next/app'
import * as Sentry from '@sentry/node'
import '../styles/app.scss'
import '@microfrontends/react-components/public/index.css'
import '@microfrontends/app-shell-v2/dist/index.css'

const SENTRY_DSN = process.browser
	? process.env.SENTRY_CLIENT_DSN
	: process.env.SENTRY_SERVER_DSN
const SENTRY_ENABLED = process.env.NODE_ENV === 'production' || process.browser

Sentry.init({
	dsn: SENTRY_DSN,
	enabled: SENTRY_ENABLED
})
interface IProps {
	err: any
}

class MyApp extends App<IProps> {
	render() {
		const { Component, pageProps } = this.props

		// Workaround for https://github.com/zeit/next.js/issues/8592
		const { err } = this.props
		const modifiedPageProps = { ...pageProps, err }

		return <Component {...modifiedPageProps} />
	}
}

export default MyApp
